@import url('https://fonts.googleapis.com/css?family=Inter:500,700&display=swap');
html,
body {
  background-color: black;
}
.App {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  overflow: auto;
  position: relative;
  font-size: 18px;
  background-color: black;
  color: var(--color);
  overflow-x: hidden;
  overflow-y: scroll;
  /* background-color: var(--primary-color);
  background-image: linear-gradient(
    -160deg,
    var(--primary-color) 60%,
    var(--highlight-color) 100%
  ); */

  h1,
  h2,
  h3 {
    color: var(--color);
    font-weight: 700;
  }

  .content {
    padding: 0 20px;
  }

  .actions {
    text-align: center;
    display: flex;
    justify-content: center;
    div {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      background-color: rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;

      &:first-child {
        margin-left: 10px;
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  small {
    font-size: 0.75em;
  }

  h1 {
    font-size: 3em;
    margin-bottom: -15px;
  }

  h3 {
    font-size: 1.25em;
    margin-bottom: 0;
  }

  .header {
    display: flex;
    padding: 0 5vw;
    vertical-align: middle;
    justify-content: space-between;

    p {
      font-size: 0.75em;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    vertical-align: flex-end;
    justify-content: center;

    img {
      height: 25px;
      width: 25px;
      object-fit: contain;
      filter: invert(1);
      margin: 5px 10px;
    }
  }

  .faded {
    opacity: 0.5;
  }

  .close {
    z-index: 2;
    position: fixed;
    top: 15px;
    right: 15px;
    filter: invert(1);
    height: 30px;
    width: 30px;
    cursor: pointer;
    opacity: 0.25;

    &:hover {
      opacity: 1;
    }
  }

  .hero {
    height: 100vh;
    width: 96vw;
    margin: 20px auto 160px;

    h2 {
      display: flex;
      justify-content: center;
    }

    &.fullscreen {
      height: 100vh;
      width: 100%;
      video {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }

    .open {
      height: 20px;
      width: 20px;
      filter: invert(1);
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    h2 {
      text-align: center;
      font-size: 2em;
      margin-bottom: 0.5em;
    }

    h4 {
      text-align: center;
      margin-bottom: 15px;
    }

    .tags {
      text-align: center;
      margin-bottom: 2.75em;
      font-size: 0.75em;

      .tag {
        padding: 5px 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 5px;
        white-space: nowrap;
      }
    }

    video {
      background: #111;
      height: 80vh;
      width: 100%;
      object-fit: contain;
      margin: auto;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 1.5em;
      margin-top: 60px;
    }
    .hero h2,
    h2 {
      margin-top: 0;
      font-size: 1.25em;
      flex-direction: column;
      line-height: 2;
    }
    p {
      font-size: 1em;
      line-height: 1.5;
    }
    .header {
      text-align: center;
      flex-direction: column;

      a {
        background-color: rgba(255, 255, 255, 0.2);
        height: 40px;
        width: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 5px;

        img {
          margin: auto !important;
        }
      }

      .contact {
        flex-direction: row;
        justify-content: center;
        height: 30px;

        img:first-child {
          margin-left: 0;
        }
      }
    }
    .tags {
      white-space: nowrap;
      overflow: scroll;
      height: 40px;
      padding: 10px;
      margin-left: 10px;
      .tag {
        &:first-child {
          margin-left: 10px;
        }
      }
    }

    .hero {
      margin-bottom: 40px;
      margin-top: 40px;
      text-align: center;

      &:last-child {
        margin-bottom: 100px;
      }
      video {
        height: 25vh;
        width: 100%;
        object-fit: cover;

        &.portrait {
          height: auto;
          width: 80%;
          margin-bottom: 100px;
        }
      }
      &.fullscreen {
        background-color: black;
        margin: 0;
        height: 100vh;
        z-index: 9999999;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 5vh 0;

        .open {
          display: none;
        }
        .tags {
          white-space: normal;
          overflow: wrap;
          display: grid;
          height: fit-content;

          .tag {
            border: none;
            padding-top: 10px;
            padding-bottom: 0;
          }
        }
        video {
          margin-top: 40vh;
          height: auto;
          width: 100vw;
        }
      }
    }
  }
}
